'use client';

import { ReadonlyURLSearchParams } from 'next/navigation';

import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { getGRSFilterBy } from '@/services/search/services/Grs';
import { SearchServiceProps } from '@/services/search/types';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
} from '@/src/types/contentStack';
import { SearchPageDigitalDataLayerProps } from '@/src/types/pages/search';
import wcsUtils from '@/src/utils/WCS';
import { getLocalWarehouseFromCookies } from '@/src/utils/cookieUtils';
import {
    useBrowseContext,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

import {
    populateCommonSearchDigitalData,
    populateFusionDigitalData,
} from './lib';

/**
 * Function to stringify the keyword if it is an array
 * @param keyword
 * @returns
 */
export function stringifyKeyword(keyword: string | string[] | undefined) {
    return Array.isArray(keyword) ? keyword[0] : keyword;
}
/**
 * Function to populate fusion props for analytics
 * @param searchParams
 * @param searchResult
 * @returns
 */
export function populateFusionAnalyticProps(
    searchParams?: ReadonlyURLSearchParams,
    searchResult?: any,
    searchService?: SearchServiceProps,
    sortType?: string,
    grsFacetConfig?: ContentStackEntryDataProps<GRSFacetConfigProperties>,
    whloc?: string
) {
    if (searchService === 'grs') {
        const filter = getGRSFilterBy({
            searchParams,
            whloc,
            grsFacetConfig,
        });

        return {
            searchTerm: searchResult?.searchResult?.correctedQuery,
            attributionToken: searchResult?.searchResult?.attributionToken,
            experimentIds: searchResult?.searchResult?.experimentInfo,
            actualSearchTerm: stringifyKeyword(
                searchParams?.get('keyword') || ''
            ),
            searchProvider: searchService.toUpperCase(),
            orderBy: sortType,
            filter,
        };
    } else {
        return {
            fusionQueryId: searchResult?.metrics?.queryId,
            searchTerm: searchResult?.metrics?.correction
                ? searchResult?.metrics?.correction
                : stringifyKeyword(searchParams?.get('keyword') || ''),
            actualSearchTerm: searchResult?.metrics?.original
                ? searchResult?.metrics?.original
                : stringifyKeyword(searchParams?.get('keyword') || ''),
            xFusionExitCode: searchResult?.metrics?.xFusionExitCode,
            searchProvider: 'LW',
            orderBy: sortType,
            filter: [searchParams?.get('refine')],
        };
    }
}

const SearchPageDigitalDataLayer: React.FC<SearchPageDigitalDataLayerProps> = ({
    lang,
    searchProps,
    pageCrumbs,
    pageName,
    productData,
    site,
}) => {
    const [cookies] = useCookies();

    useEffect(() => {
        const sessionStatus = wcsUtils.isWcsSessionEstablished()
            ? 'Authenticated'
            : 'Unauthenticated';

        if (site && lang) {
            populateCommonSearchDigitalData(
                lang,
                getLocalWarehouseFromCookies(cookies),
                sessionStatus,
                site,
                pageName,
                pageCrumbs
            );
        } else if (searchProps) {
            populateFusionDigitalData(searchProps, pageName);
        } else if (productData) {
            window?.digitalData?.products?.push(productData);
        }
    }, [site, lang, searchProps, productData, cookies, pageCrumbs, pageName]);
    return null;
};

export const SearchPageDigitalDataLayerWithSearchResults = ({
    grsFacetConfig,
    ...rest
}: SearchPageDigitalDataLayerProps) => {
    const { queryParams: searchParams } = useQueryParams();
    const { searchResult, searchService, sortType } = useSearchContext();
    const { warehouse } = useBrowseContext();

    const whloc = warehouse?.nearestWarehouse?.catalog;

    const searchProps = populateFusionAnalyticProps(
        searchParams,
        searchResult,
        searchService,
        sortType,
        grsFacetConfig,
        whloc
    );

    return <SearchPageDigitalDataLayer searchProps={searchProps} {...rest} />;
};

export default SearchPageDigitalDataLayer;
