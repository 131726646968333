export const GRS_CATEGORY_INFO_FACET_KEY = 'attributes.category_info';
export const GRS_CATEGORY_NAMES_FACET_KEY = 'attributes.category_names';
export const GRS_SHOW_OUT_OF_STOCK_FACET_KEY = 'show_out_of_stock';
export const GRS_RATING_FACET_KEY = 'rating';
export const GRS_BUY_IN_WAREHOUSE_FACET_KEY = 'buy_in_warehouse';
export const GRS_PROGRAM_TYPES_FACET_KEY = 'attributes.program_types';
export const GRS_BRANDS_FACET_KEY = 'brands';
export const GRS_WAREHOUSE_PICKUP_FACET_KEY = 'warehouse_pickup';
export const GRS_US_PRICE_FACET_KEY = 'inventory(847, price)';
export const GRS_CA_PRICE_FACET_KEY = 'inventory(894, price)';
export const GRS_RATING_LABEL_SUFFIX_KEY = 'ratinglabelsuffix';
export const GRS_RATING_TAG_LABEL_KEY = 'ratingtaglabel';
export const GRS_PRICE_LABEL_KEY = 'pricelabel';
export const GRS_WAREHOUSE_LABEL_KEY = 'warehouselabel';
export const GRS_DELIVERY_LOCATION_LABEL_KEY = 'deliverylocationlabel';
