import {
    CATEGORY_PATH_PREFIX,
    LW_AB_DEFAULT_VALUE,
    LW_AB_STORAGE_NAME,
    SEARCH_PATH_PREFIX,
} from '@/constants/index';
import {
    ITEM_LOCATION_PRICING_FACET_KEY,
    ITEM_PROGRAM_FACET_KEY,
    ITEM_RATING_FACET_KEY,
} from '@/constants/index';
import { SearchResult } from '@/src/types/searchQuery';
import { getCookie } from '@/utils/cookieUtils';
import { modernizeCategoryLink } from '@/utils/links';
import { getSingleAttrValue } from '@/utils/searchfacets';

import type { WarehouseCookieValue } from './types';

/**
 * Take the index of the item returned from LW to calculate the current page
 * used in pagination navigation
 */
export function getCurrentPage(start: number, resultsPerPage: number) {
    if (start <= 0 || typeof start !== 'number') {
        return 1;
    }
    return Math.ceil(start / resultsPerPage) + 1;
}

export function getNumberOfPages(totalDocs: number, resultsPerPage: number) {
    return Math.ceil(totalDocs / resultsPerPage);
}

/**
 * Search URL includes a page parameter, but LW uses a start at item param,
 * so this maps from a page # to the first item on that page
 */
export function getFirstItemFromPageNumber(
    currentPage: number,
    resultsPerPage: number
) {
    if (currentPage <= 1 || typeof currentPage !== 'number') {
        return 0;
    }
    return currentPage * resultsPerPage - resultsPerPage;
}

export function isBDSite(site: string | undefined) {
    return site?.slice(-2).toLowerCase() === 'bd';
}

export function getLocationsFromCookie(
    warehouseCookieValue: WarehouseCookieValue
) {
    const defaultReturnValue = ['*'];

    if (!warehouseCookieValue) {
        return { loc: defaultReturnValue.join(','), whloc: '', mdo: '' };
    }
    try {
        let returnValues = [];

        if (warehouseCookieValue.groceryCenters) {
            returnValues.push(...warehouseCookieValue.groceryCenters);
        }
        if (warehouseCookieValue.nearestWarehouse?.catalog) {
            returnValues.push(warehouseCookieValue.nearestWarehouse.catalog);
        }

        if (warehouseCookieValue.distributionCenters) {
            returnValues.push(...warehouseCookieValue.distributionCenters);
        }

        // If we don't have a distribution centers, the other centers don't
        // give us any value, so default to all
        if (
            !warehouseCookieValue.distributionCenters ||
            !warehouseCookieValue.distributionCenters.length
        ) {
            returnValues = defaultReturnValue;
        }

        return {
            loc: returnValues.join(','),
            whloc: warehouseCookieValue?.nearestWarehouse?.catalog || '',
            mdo:
                warehouseCookieValue?.pickUpCenters &&
                warehouseCookieValue?.pickUpCenters.length > 0
                    ? warehouseCookieValue?.pickUpCenters[0]
                    : '',
        };
    } catch (e) {
        console.error(e);
        return { loc: defaultReturnValue.join(','), whloc: '' };
    }
}

/**
 * Used to filter out price refinements during search query building as they
 * need custom treatment to include multiple ranges
 */
export function isPriceRefinement(refinement: string) {
    return refinement
        .toLowerCase()
        .startsWith(ITEM_LOCATION_PRICING_FACET_KEY.toLowerCase());
}

// fq={!tag=<facet key>}<facet key>:("<filter value>")
export function generateFacetFilterParam(refinement: string) {
    const [key, value] = refinement.split(/-(.*)/s);

    return `&fq=${key}:("${encodeURIComponent(value!)}")`;
}

export function isAttrRefinement(refinement: string) {
    const [facetKey] = refinement.split(/-(.*)/s);
    if (!facetKey) {
        return false;
    }
    return facetKey.toLowerCase().endsWith('_attr');
}

export function isRatingRefinement(refinement: string) {
    return refinement.toLowerCase().startsWith(ITEM_RATING_FACET_KEY);
}

export function isProgramRefinement(refinement: string) {
    return refinement.toLowerCase().startsWith(ITEM_PROGRAM_FACET_KEY);
}

export function buildMultiSelectFacet(refinements: string[]): string {
    // Build a mapping of all values for each facet key
    let attrs: { [key: string]: string[] } = {};

    let facets = '';

    for (const attr of refinements) {
        let [facetKey, val] = attr.split(/-(.*)/s);
        if (!facetKey || !val) {
            continue;
        }

        if (!attrs[facetKey]) {
            attrs[facetKey] = [];
        }

        if (facetKey.toLowerCase() === 'delivery_type_attr') {
            val = val.replace(/\+/g, ' ');
        }

        attrs[facetKey]!.push(val);
    }

    // Apply collected facet keys to URL
    for (const facetKey in attrs) {
        facets += `&fq=${encodeURIComponent(`{!tag=${facetKey}}${facetKey}:("${attrs[facetKey]!.join('" OR "')}")`)}`;
    }

    return facets;
}

export function getLWABValue() {
    try {
        return (
            localStorage.getItem(LW_AB_STORAGE_NAME) ||
            getCookie(LW_AB_STORAGE_NAME) ||
            LW_AB_DEFAULT_VALUE
        );
    } catch (e) {
        return LW_AB_DEFAULT_VALUE;
    }
}

export const getParam = (params: URLSearchParams, param: string) => {
    return params.get(param) || '';
};

export function prependCategoryUrl(
    category_path: string | undefined,
    url: string,
    queryParams: string | undefined
) {
    if (queryParams) {
        return modernizeCategoryLink(category_path, `${url}?${queryParams}`);
    }
    return modernizeCategoryLink(category_path, `${url}`);
}

// Update page title with refinements
// If we have a single attribute param, add it to the page title
export function updatePageTitle(
    searchParams: URLSearchParams,
    searchResult: SearchResult | undefined,
    kw?: string
) {
    if (searchParams.get('refine') && kw) {
        const value = getSingleAttrValue(searchParams.get('refine'));

        document.title = `${value} ${kw} | Costco`;
    } else if (kw) {
        document.title = `${kw} | Costco`;
    } else if (searchResult?.breadcrumb) {
        const lastBreadcrumb = searchResult.breadcrumb.slice(-1)?.[0]?.name;
        document.title = `${lastBreadcrumb} | Costco`;
    } else {
        document.title = `Search | Costco`;
    }
}

export function isCategoryPath(path: string) {
    return path.startsWith(CATEGORY_PATH_PREFIX);
}

export function isSearchPath(path: string = '') {
    return path.toLowerCase() === SEARCH_PATH_PREFIX;
}

export function generateEmptySearchResult(): SearchResult {
    return {
        isAdTargetingExceptionEnabled: false,
        isAdTargetingExplicitlyDisabled: false,
        isAdTargetingEnabled: false,
        redirect: '',
        docs: [],
        facets: [],
        variants: [],
        metrics: {
            original: undefined,
            correction: undefined,
            queryTime: 0,
            queryId: '',
            totalTime: 0,
            semantic: false,
            xFusionExitCode: '',
        },
        pagination: {
            currentPage: 0,
            totalDocs: 0,
            totalPages: 0,
        },
    };
}

//
function productNameToUrl(productName: string): string {
    return productName.toLowerCase().replace(/[^a-z0-9]/g, '-');
}

/**
 * Redirect for Item Number, Product Number and Model Number Search to PDP
 *
 * 1. Number of search results returned  “numFound" in API response must be 1 AND satisfy one of the following criteria's
 * 2. Search term exactly matches “item_number” from API response
 *
 * Returns false to do nothing, and a string if a redirect should be done
 */
export function checkForItemNumberRedirect(
    keyword: string | string[],
    searchResult: SearchResult | undefined
): false | string {
    if (Array.isArray(keyword)) {
        keyword = keyword[0] as string;
    }
    if (!keyword || searchResult.docs.length !== 1) {
        return false;
    }

    const item = searchResult?.docs[0];

    if (
        item.item_number === keyword ||
        item.group_id === keyword ||
        (Array.isArray(item.Model_attr) &&
            item.Model_attr.some(
                (attr: string | number) =>
                    attr.toString().toLowerCase() === keyword.toLowerCase()
            ))
    ) {
        return `${productNameToUrl(item.item_product_name)}.product.${item.group_id}.html`;
    }

    return false;
}

export function getPageIdFromPath(path: string | undefined | null) {
    if (!path) {
        return;
    }

    const pageId = path.split('/').at(-1);

    return pageId?.replace('.html', '');
}
