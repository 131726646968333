export const GRS_PROVIDER_FEATURE_TOGGLE_CONFIG_DEVELOPMENT_KEY =
    'grs_provider_feature_toggle';
export const GRS_PROVIDER_BETA_RELEASE_FEATURE_TOGGLE_CONFIG_DEVELOPMENT_KEY =
    'grs_beta_release_enabled';
export const GRS_SEARCH_PROVIDER_QUERY_PARAM_VALUE = 'grs';
export const GRS_SEARCH_PROVIDER_SESSION_STORAGE_VALUE = 'grs';
export const SEARCH_PROVIDER_QUERY_PARAM_KEY = 'sp';
export const SEARCH_PROVIDER_SESSION_STORAGE_KEY = 'searchProvider';
export const SEARCH_SERVICE_PROVIDERS = {
    LUCIDWORKS: 'lucidworks',
    GRS: 'grs',
} as const;
