import type { ReadonlyURLSearchParams } from 'next/navigation';
import {
    GRSFacet,
    SearchResultGRS,
} from 'src/services/search/services/GRS.types';

import { SHOP_BY_PREFIX } from '@/components/Analytics/constants';
import {
    getFacetLabel,
    updateSearchParams,
} from '@/components/SearchResultsFacets/utils';
import { BUY_IN_WAREHOUSE_TEXT_KEY, PICK_UP_TEXT_KEY } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import { getGRSFacetsFromConfig } from '@/services/search/services/Grs';
import { FacetBucketNormalized } from '@/src/components/SearchResultsFacets/types';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
} from '@/src/types/contentStack';
import { splitByCommaOutsideParentheses } from '@/utils/searchfacets';
import {
    ComposableWindowArray,
    getComposableTextByDevFieldKey,
} from '@costcolabs/forge-digital-components';

import { CURRENT_PAGE } from './constants';
import {
    GRS_BRANDS_FACET_KEY,
    GRS_BUY_IN_WAREHOUSE_FACET_KEY,
    GRS_CATEGORY_INFO_FACET_KEY,
    GRS_CA_PRICE_FACET_KEY,
    GRS_PRICE_LABEL_KEY,
    GRS_PROGRAM_TYPES_FACET_KEY,
    GRS_RATING_FACET_KEY,
    GRS_RATING_LABEL_SUFFIX_KEY,
    GRS_RATING_TAG_LABEL_KEY,
    GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
    GRS_US_PRICE_FACET_KEY,
    GRS_WAREHOUSE_PICKUP_FACET_KEY,
} from './grs.constants';
import type { FacetNormalized } from './types';

export const normalizeGRSFacets = (
    grsFacets: GRSFacet[],
    grsFacetConfig: ContentStackEntryDataProps<GRSFacetConfigProperties>
): FacetNormalized[] => {
    const normalizedFacets: FacetNormalized[] = [];
    const grsFacetsFromConfig = getGRSFacetsFromConfig(grsFacetConfig);
    const ratingLabelSuffix = getKeyValue(
        GRS_RATING_LABEL_SUFFIX_KEY,
        grsFacetConfig
    );

    grsFacets.forEach(facet => {
        if (!grsFacetsFromConfig[facet.key]) {
            return;
            // Rating facet
        } else if (facet.key === GRS_RATING_FACET_KEY) {
            normalizedFacets.push({
                facetKey: facet.key,
                label: grsFacetsFromConfig[facet.key]?.label ?? '',
                multiSelect: facet.dynamicFacet,
                queryParamKey:
                    grsFacetsFromConfig[facet.key]?.queryParamKey ?? facet.key,
                buckets: facet.values.map(value => {
                    return {
                        low: value.interval?.minimum ?? value.minValue,
                        high: value.interval?.maximum ?? value.maxValue,
                        count: value.count,
                        val: value.interval?.minimum
                            ? `${value.interval.minimum} ${ratingLabelSuffix}`
                            : '',
                    };
                }),
            });
            // Warehouse Price facet
        } else if (
            facet.key === GRS_US_PRICE_FACET_KEY ||
            facet.key === GRS_CA_PRICE_FACET_KEY
        ) {
            normalizedFacets.push({
                facetKey: facet.key,
                label: grsFacetsFromConfig[facet.key]?.label ?? '',
                multiSelect: facet.dynamicFacet,
                queryParamKey:
                    grsFacetsFromConfig[facet.key]?.queryParamKey ?? facet.key,
                buckets: facet.values.map(value => {
                    return {
                        low: value.interval?.minimum ?? value.minValue,
                        high: value.interval?.maximum ?? value.maxValue,
                        count: value.count,
                        val:
                            value.interval?.minimum !== undefined &&
                            value.interval?.maximum !== undefined
                                ? getComposableTextByDevFieldKey({
                                      devFieldKey: GRS_PRICE_LABEL_KEY,
                                      composableWindow:
                                          grsFacetConfig.composable_window as ComposableWindowArray,
                                      templateVariables: {
                                          priceMinimum:
                                              value.interval.minimum.toString(),
                                          priceMaximum:
                                              value.interval.maximum.toString(),
                                      },
                                  })
                                : '',
                    };
                }),
            });
        } else if (facet.key === GRS_CATEGORY_INFO_FACET_KEY) {
            normalizedFacets.push({
                facetKey: facet.key,
                label: grsFacetsFromConfig[facet.key]?.label ?? '',
                multiSelect: facet.dynamicFacet,
                queryParamKey:
                    grsFacetsFromConfig[facet.key]?.queryParamKey ?? facet.key,
                buckets: facet.values.map(value => {
                    const cateogryAttributes = getCategoryAttributes(
                        value.value ?? ''
                    );
                    return {
                        low: value.minValue,
                        high: value.maxValue,
                        count: value.count,
                        val: value.value ?? '',
                        meta: cateogryAttributes?.url,
                    };
                }),
            });
        } else {
            normalizedFacets.push({
                facetKey: facet.key,
                label: grsFacetsFromConfig[facet.key]?.label ?? '',
                multiSelect: facet.dynamicFacet,
                queryParamKey:
                    grsFacetsFromConfig[facet.key]?.queryParamKey ?? facet.key,
                buckets: facet.values.map(value => {
                    return {
                        low: value.minValue,
                        high: value.maxValue,
                        count: value.count,
                        val: value.value ?? '',
                    };
                }),
            });
        }
    });

    // Add Show Out of Stock facet for all
    // Always present, no facet returned by GRS to indicate if out of stock items should be shown
    normalizedFacets.push({
        facetKey: GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
        label:
            grsFacetsFromConfig[GRS_SHOW_OUT_OF_STOCK_FACET_KEY]?.label ?? '',
        multiSelect: false,
        queryParamKey:
            grsFacetsFromConfig[GRS_SHOW_OUT_OF_STOCK_FACET_KEY]
                ?.queryParamKey ?? GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
        buckets: [
            {
                count: 1,
                val: '1',
                low: 0,
                high: 0,
            },
        ],
    });

    return normalizedFacets;
};

export function remapGRSFacetDisplayOrder(facet: FacetNormalized) {
    if (facet.facetKey === GRS_CATEGORY_INFO_FACET_KEY) {
        facet.display_order = 0;
    } else if (facet.facetKey === GRS_PROGRAM_TYPES_FACET_KEY) {
        facet.display_order = 1;
    } else if (facet.facetKey === GRS_SHOW_OUT_OF_STOCK_FACET_KEY) {
        facet.display_order = 2;
    } else if (facet.facetKey === GRS_US_PRICE_FACET_KEY) {
        facet.display_order = 3;
    } else if (facet.facetKey === GRS_CA_PRICE_FACET_KEY) {
        facet.display_order = 4;
    } else if (facet.facetKey === GRS_RATING_FACET_KEY) {
        facet.display_order = 5;
    } else if (facet.facetKey === GRS_BRANDS_FACET_KEY) {
        facet.display_order = 6;
    } else if (facet.display_order === undefined) {
        facet.display_order = 7;
    }
    return facet;
}

export const updateGRSRefinements = (
    updateSearchParamsProvider: (queryParams: ReadonlyURLSearchParams) => void,
    facet: FacetNormalized,
    bucket: FacetBucketNormalized,
    isEnabled: boolean,
    urlParams: ReadonlyURLSearchParams
) => {
    const queryParams = new URLSearchParams(urlParams);

    //remove pagination page counter when applying filters to avoid ending up no search results
    queryParams.delete(CURRENT_PAGE);
    const queryParamKey = facet.queryParamKey ?? facet.facetKey;
    const queryParamValue = getGRSQueryParamValue(facet, bucket);

    if (isEnabled) {
        // If enabled, check if value is in query params and add if not
        if (queryParams.has(queryParamKey)) {
            const existingValues = queryParams.get(queryParamKey) ?? '';
            const existingValuesSplit =
                splitByCommaOutsideParentheses(existingValues);
            if (!existingValuesSplit?.includes(queryParamValue)) {
                const updatedValues = [...existingValuesSplit, queryParamValue];
                queryParams.set(queryParamKey, updatedValues.join(','));
            }
        } else {
            queryParams.append(queryParamKey, queryParamValue);
        }
    } else {
        // If disabled, remove value from query params
        if (queryParams.has(queryParamKey)) {
            const existingValues = queryParams.get(queryParamKey) ?? '';
            const existingValuesSplit =
                splitByCommaOutsideParentheses(existingValues);
            const updatedValues = existingValuesSplit.filter(
                value => value !== queryParamValue
            );
            if (updatedValues.length > 0) {
                queryParams.set(queryParamKey, updatedValues.join(','));
            } else {
                queryParams.delete(queryParamKey);
            }
        }
    }

    // On refinement change, back to page one
    queryParams.delete('page');

    updateSearchParams(updateSearchParamsProvider, queryParams);
};

export const checkGRSRefinementApplied = ({
    facet,
    bucket,
    searchParams,
}: {
    facet: FacetNormalized;
    bucket: FacetBucketNormalized;
    searchParams: ReadonlyURLSearchParams;
}) => {
    const queryParamKey = facet.queryParamKey ?? facet.facetKey;
    const queryParamValue = getGRSQueryParamValue(facet, bucket);
    const queryParamExistingValue = searchParams.get(queryParamKey) ?? '';

    const queryParamExistingValuesCommaSeparated =
        splitByCommaOutsideParentheses(queryParamExistingValue);

    const inUrl =
        queryParamExistingValuesCommaSeparated.includes(queryParamValue);

    return !!inUrl;
};

export const grsRangeValueFacets = [
    GRS_CA_PRICE_FACET_KEY,
    GRS_US_PRICE_FACET_KEY,
    GRS_RATING_FACET_KEY,
];
export const grsBooleanValueFacets = [
    GRS_BUY_IN_WAREHOUSE_FACET_KEY,
    GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
    GRS_WAREHOUSE_PICKUP_FACET_KEY,
];

export const getGRSQueryParamValue = (
    facet: FacetNormalized,
    bucket: FacetBucketNormalized
): string => {
    if (grsRangeValueFacets.includes(facet.facetKey)) {
        return `(${bucket.low},${bucket.high})`;
    } else if (grsBooleanValueFacets.includes(facet.facetKey)) {
        return 'true';
    } else {
        return bucket.val.toString();
    }
};

export const getSelectedFacets = (
    searchParams: ReadonlyURLSearchParams,
    normalizedFacets: FacetNormalized[]
): FacetNormalized[] => {
    const selectedFacets: FacetNormalized[] = normalizedFacets
        .map(facet => {
            const filteredBuckets = facet.buckets
                .map(bucket => {
                    const applied = checkGRSRefinementApplied({
                        facet,
                        bucket,
                        searchParams,
                    });

                    return applied ? { ...bucket } : null;
                })
                .filter(bucket => bucket !== null);

            return filteredBuckets.length > 0
                ? { ...facet, buckets: filteredBuckets }
                : null;
        })
        .filter(facet => facet !== null);
    return selectedFacets;
};

export function getSelectGRSFacetsFromUrl({
    searchParams,
    searchResult,
    grsFacetConfig,
}: {
    searchParams: ReadonlyURLSearchParams;
    searchResult: SearchResultGRS;
    grsFacetConfig?: ContentStackEntryDataProps<GRSFacetConfigProperties>;
}) {
    const normalizedFacets =
        grsFacetConfig &&
        normalizeGRSFacets(searchResult.searchResult.facets, grsFacetConfig);

    const selectedGRSFacets = getSelectedFacets(
        searchParams,
        normalizedFacets!
    );

    return selectedGRSFacets;
}

export const getFacetTagLabel = ({
    facet,
    bucket,
    config,
    grsFacetConfig,
}: {
    facet: FacetNormalized;
    bucket: FacetBucketNormalized;
    config: ContentStackEntryDataProps;
    grsFacetConfig: ContentStackEntryDataProps<GRSFacetConfigProperties>;
}) => {
    let label = getFacetLabel(bucket.val.toString(), config);

    if (facet.facetKey === GRS_BUY_IN_WAREHOUSE_FACET_KEY) {
        label = getKeyValue(BUY_IN_WAREHOUSE_TEXT_KEY, config)!;
    } else if (facet.facetKey === GRS_WAREHOUSE_PICKUP_FACET_KEY) {
        label = getKeyValue(PICK_UP_TEXT_KEY, config)!;
    } else if (facet.facetKey === GRS_SHOW_OUT_OF_STOCK_FACET_KEY) {
        label = facet.label;
    } else if (facet.facetKey === GRS_RATING_FACET_KEY) {
        const composableRatingTagText =
            grsFacetConfig &&
            getComposableTextByDevFieldKey({
                devFieldKey: GRS_RATING_TAG_LABEL_KEY,
                composableWindow:
                    grsFacetConfig.composable_window as ComposableWindowArray,
                templateVariables: {
                    ratingValue: bucket.low.toString(),
                },
            });
        label = composableRatingTagText ?? null;
    } else if (!label) {
        label = bucket.val.toString();
    }
    return label;
};

export const getFilterType = (facetKey: string) => {
    let filterType;

    if (facetKey === GRS_RATING_FACET_KEY) {
        filterType = 'Customer Reviews';
    } else if (
        facetKey === GRS_CA_PRICE_FACET_KEY ||
        facetKey === GRS_US_PRICE_FACET_KEY
    ) {
        filterType = 'Price';
    } else if (facetKey === GRS_PROGRAM_TYPES_FACET_KEY) {
        filterType = 'Location';
    } else if (facetKey.includes('attributes.')) {
        filterType = facetKey.split('attributes.')[1];
    } else {
        filterType = facetKey;
    }

    return filterType ? SHOP_BY_PREFIX + filterType.replace(/_/g, ' ') : '';
};

export const getCategoryAttributes = (
    categoryInfo: string
): {
    label: string | undefined;
    url: string | undefined;
    id: string | undefined;
    level: number | undefined;
    parent: string | undefined | null;
    l1cateogry: string | undefined | null;
    l2cateogry: string | undefined | null;
} => {
    const parts = categoryInfo?.split('_');
    const id = parts[0];
    const label = parts[1];
    const hierarchy = parts[2];
    const url = parts[3];
    const hierarchyArr = hierarchy?.split('|');

    const level =
        !hierarchyArr?.length || hierarchyArr?.length === 0
            ? 1
            : hierarchyArr?.length;

    const parent =
        hierarchyArr?.length && hierarchyArr.length > 1
            ? hierarchyArr?.[hierarchyArr.length - 2]
            : null;

    const l1cateogry =
        hierarchyArr?.length && hierarchyArr.length > 0
            ? hierarchyArr?.[0]
            : null;

    const l2cateogry =
        hierarchyArr?.length && hierarchyArr.length > 1
            ? hierarchyArr?.[1]
            : null;

    return {
        label,
        url,
        id,
        level,
        parent,
        l1cateogry,
        l2cateogry,
    };
};
