'use client';

import { ReadonlyURLSearchParams } from 'next/navigation';

import React, {
    ReactNode,
    createContext,
    memo,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    GRS_SEARCH_PROVIDER_QUERY_PARAM_VALUE,
    SEARCH_PROVIDER_QUERY_PARAM_KEY,
    SEARCH_SERVICE_PROVIDERS,
} from '@/constants/grs';
import { ADOBE_SESSION_ID_KEY } from '@/src/components/Adobe/constants';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
    GrsConfig,
    SearchPageConfigProperties,
    SearchServiceConfig,
} from '@/src/types/contentStack';
import { SearchParams } from '@/src/types/searchQuery';
import { PageType } from '@/types/pages/search';
import {
    getAdobeSessionId,
    getIsGRSSearchProviderSessionStorageSet,
    setGRSSearchProviderSessionStorage,
} from '@/utils/grs';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import {
    createUUID,
    useBrowseContext,
    useLocalForage,
    useQueryParams,
    useSearchProvider,
} from '@costcolabs/forge-digital-components';

import { DEFAULT_USER_STATE } from './constants';
import { GrsQuery, isSearchResultGRS } from './services/Grs';
import { LucidWorksQuery, isSearchResultLW } from './services/LucidWorks';
import type {
    SearchContextType,
    SearchResult,
    WarehouseCookieValue,
} from './types';
import {
    checkForItemNumberRedirect,
    getLocationsFromCookie,
    isBDSite,
    updatePageTitle,
} from './utils';

// Create the context with a default value
const SearchContext = createContext<SearchContextType | undefined>(undefined);

type SearchProviderProps = {
    lang: LocaleProps;
    config: ContentStackEntryDataProps;
    serviceConfig: SearchPageConfigProperties;
    children: ReactNode;
    requestHeaders: SearchServiceConfig['required_request_headers'];
    NoResultsComponent: ReactNode;
    loc?: string;
    whloc?: string;
    userZipCode?: string;
    userLocation?: string;
    pageType: PageType;
    isEnabled?: boolean;
    enableLivePreview?: boolean;
    livePreviewKeyword?: string;
    mdo?: string;
    site: string;
    grsConfig: GrsConfig | undefined;
    grsFacetConfig:
        | ContentStackEntryDataProps<GRSFacetConfigProperties>
        | undefined;
    isGRSFeatureFlagEnabled: boolean;
    isGRSBetaReleaseFeatureFlagEnabled: boolean;
    categoryTitle: string | undefined;
};

const SearchProviderComponent = ({
    lang,
    serviceConfig,
    requestHeaders,
    children,
    NoResultsComponent,
    loc,
    whloc,
    userZipCode,
    userLocation = DEFAULT_USER_STATE,
    mdo,
    pageType,
    isEnabled = true,
    enableLivePreview,
    livePreviewKeyword,
    site,
    grsConfig,
    grsFacetConfig,
    isGRSFeatureFlagEnabled,
    isGRSBetaReleaseFeatureFlagEnabled,
    categoryTitle,
}: SearchProviderProps) => {
    const { queryParams: searchParams } = useQueryParams();

    const [searchResult, setSearchResult] = useState<
        SearchResult | undefined
    >();
    const [searchedParams, setSearchedParams] = useState<
        SearchParams | undefined
    >();
    const [isSearchResultsLoading, setIsSearchResultsLoading] =
        useState<boolean>(true);

    const searchService = useSearchProvider({
        isGRSFeatureFlagEnabled,
        isGRSBetaReleaseFeatureFlagEnabled,
    });

    const [sortType, setSortType] = useState<string>('');
    const {
        data: adobeSessionIdLocalForage,
        error: localForageError,
        isStoreInitialized: isLocalForageInitialzed,
    } = useLocalForage<string>(ADOBE_SESSION_ID_KEY, createUUID());

    const adobeSessionID = getAdobeSessionId({
        searchService,
        adobeSessionIdLocalForage,
        hasLocalForageError: !!localForageError,
        isLocalForageInitialzed,
    });

    // Set searchProvider session storage value for beta release
    useEffect(() => {
        const searchProviderQueryParam = searchParams.get(
            SEARCH_PROVIDER_QUERY_PARAM_KEY
        );

        const isSearchProviderQueryParamGRS =
            searchProviderQueryParam?.toLowerCase() ===
            GRS_SEARCH_PROVIDER_QUERY_PARAM_VALUE;

        const isGRSSearchProviderSessionStorageSet =
            getIsGRSSearchProviderSessionStorageSet();

        if (
            isSearchProviderQueryParamGRS &&
            !isGRSSearchProviderSessionStorageSet
        ) {
            setGRSSearchProviderSessionStorage();
        }
    }, [
        isGRSBetaReleaseFeatureFlagEnabled,
        isGRSFeatureFlagEnabled,
        searchParams,
    ]);

    useEffect(() => {
        const run = async () => {
            setIsSearchResultsLoading(true);
            try {
                let effectiveSearchParams = searchParams;

                if (enableLivePreview && livePreviewKeyword) {
                    const paramsObject = searchParams
                        ? Object.fromEntries(searchParams.entries())
                        : {};
                    paramsObject['keyword'] = livePreviewKeyword;
                    effectiveSearchParams = new URLSearchParams(
                        paramsObject
                    ) as ReadonlyURLSearchParams;
                }

                let searchResult: SearchResult;

                switch (searchService) {
                    case SEARCH_SERVICE_PROVIDERS.LUCIDWORKS:
                        searchResult = await LucidWorksQuery(
                            effectiveSearchParams,
                            lang,
                            requestHeaders,
                            serviceConfig,
                            { loc, whloc, userLocation, mdo },
                            pageType,
                            site
                        );
                        break;
                    case SEARCH_SERVICE_PROVIDERS.GRS:
                        searchResult = await GrsQuery(
                            grsConfig,
                            grsFacetConfig,
                            effectiveSearchParams,
                            lang,
                            serviceConfig,
                            {
                                loc,
                                whloc,
                                userLocation,
                                mdo,
                                userZipCode,
                            },
                            pageType,
                            site,
                            adobeSessionID ?? '',
                            categoryTitle
                        );
                        break;
                    default:
                        throw new Error(
                            `Search service "${searchService}" not supported`
                        );
                }

                const kw = effectiveSearchParams.get('keyword')!;

                const redirect =
                    searchService === SEARCH_SERVICE_PROVIDERS.LUCIDWORKS &&
                    checkForItemNumberRedirect(kw, searchResult);

                if (redirect) {
                    window.location.href = redirect;
                    return;
                }

                if (searchResult?.redirect) {
                    /* Bug 114623: in case of category page redirect, onclick of back button was not navigating user to page it came from */
                    // replace method of location is used so that it doesn't save the current request in the browser history
                    window.location.replace(searchResult.redirect);

                    return;
                }

                updatePageTitle(effectiveSearchParams, searchResult, kw);
                setSearchResult(searchResult);

                setSearchedParams(
                    Object.fromEntries(effectiveSearchParams.entries())
                );
                setIsSearchResultsLoading(false);
            } catch (err) {
                console.error(err);
                setIsSearchResultsLoading(false);
            }
        };

        if (loc && userLocation && searchService) {
            if (
                searchService === SEARCH_SERVICE_PROVIDERS.GRS &&
                // adobeSessionId will be null until read from indexedDB
                // GRS search api throws error if whloc is not set
                (!adobeSessionID || !whloc)
            ) {
                // Skip search run if localforage is not initialized as wrong adobe session id will be used
                console.log(`Skipping search run, awaiting initialization`);
            } else {
                run();
            }
        } else {
            console.log(
                `Skipping search run, loc: ${loc} | ul: ${userLocation}`
            );
        }
    }, [
        adobeSessionID,
        categoryTitle,
        enableLivePreview,
        pageType,
        lang,
        livePreviewKeyword,
        loc,
        requestHeaders,
        searchParams,
        searchService,
        serviceConfig,
        userLocation,
        whloc,
        site,
        grsConfig,
        grsFacetConfig,
        userZipCode,
        mdo,
    ]);

    // Search is actively enabled, or a refinement tag is present on a search disabled page
    const isSearchActive = isEnabled || !!searchParams.get('refine');
    if (isEnabled === false) {
        return (
            <SearchContext.Provider
                value={{
                    searchResult,
                    isSearchResultsLoading,
                    searchedParams,
                    searchService,
                    sortType,
                    setSortType,
                }}
            >
                {children}
            </SearchContext.Provider>
        );
    }

    const hasLWResults =
        searchService === SEARCH_SERVICE_PROVIDERS.LUCIDWORKS &&
        isSearchResultLW(searchResult) &&
        searchResult?.docs?.length > 0;

    const hasGRSResults =
        searchService === SEARCH_SERVICE_PROVIDERS.GRS &&
        isSearchResultGRS(searchResult) &&
        searchResult?.searchResult?.results?.length > 0;

    const isNoResultsComponentUsed =
        isSearchActive &&
        !isSearchResultsLoading &&
        (!searchResult || (!hasLWResults && !hasGRSResults));

    return (
        <SearchContext.Provider
            value={{
                searchResult,
                isSearchResultsLoading,
                searchedParams,
                searchService,
                sortType,
                setSortType,
            }}
        >
            {isNoResultsComponentUsed ? NoResultsComponent : children}
            <div
                dangerouslySetInnerHTML={{
                    __html: `<!-- 
                        resultsLeadToFSACHDI: ${searchResult?.isAdTargetingExplicitlyDisabled ? 1 : 0}
                        isFSACHDIExceptionSet: ${searchResult?.isAdTargetingExceptionEnabled ? 1 : 0}
                        doNotTrackLayout: ${!searchResult?.isAdTargetingEnabled}
                    -->`,
                }}
            />
        </SearchContext.Provider>
    );
};

const MemoizedSearchProviderComponent = memo(SearchProviderComponent);

// Create the provider component
export const SearchProvider = (props: SearchProviderProps) => {
    const { warehouse, deliveryLocation, bdWarehouseNumber } =
        useBrowseContext();

    let { loc, whloc, mdo } = getLocationsFromCookie(
        warehouse as WarehouseCookieValue
    );
    const { state, zipCode } = deliveryLocation || {};

    // BD does not use the whloc param, and uses the BD warehouse cookie for loc instead
    if (isBDSite(props.site)) {
        whloc = '';
        loc =
            bdWarehouseNumber?.value || props.serviceConfig.defaultBDWarehouse;
    }

    return (
        <MemoizedSearchProviderComponent
            loc={loc}
            whloc={whloc}
            userLocation={state}
            userZipCode={zipCode}
            mdo={mdo}
            {...props}
        />
    );
};

// Create a custom hook to use the SearchContext
export const useSearchContext = (): SearchContextType => {
    const context = useContext(SearchContext);
    if (context === undefined) {
        throw new Error(
            'useSearchContext must be used within a SearchProvider'
        );
    }
    return context;
};
