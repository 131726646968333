'use client';

/**
 * GRS search service
 * Each search service needs a URL constructor and a response parser.
 */
import { getNumberOfPages } from '../utils';
import type { ReadonlyURLSearchParams } from 'next/navigation';

import {
    GRS_BUY_IN_WAREHOUSE_FACET_KEY,
    GRS_CATEGORY_NAMES_FACET_KEY,
    GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
    GRS_WAREHOUSE_PICKUP_FACET_KEY,
} from '@/components/SearchResultsFacets/grs.constants';
import {
    grsBooleanValueFacets,
    grsRangeValueFacets,
} from '@/components/SearchResultsFacets/grs.lib';
import { PageTypes } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import { GRS_PRICE_FACET_WAREHOUSE_KEY } from '@/services/search/services/grs.constants';
import type {
    GrsConfig,
    SearchPageConfigProperties,
} from '@/src/types/contentStack';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfig,
    GRSFacetConfigProperties,
} from '@/types/contentStack';
import { splitByCommaOutsideParentheses } from '@/utils/searchfacets';
import type { LocaleProps } from '@costcolabs/forge-digital-components';

import { SearchResultGRS, WarehouseSearchParams } from './GRS.types';

const grsKeyQueryMap: Record<string, (warehouseId: string) => string> = {
    [GRS_SHOW_OUT_OF_STOCK_FACET_KEY]: () => 'SHOW_OUT_OF_STOCK',
    [GRS_BUY_IN_WAREHOUSE_FACET_KEY]: warehouseId =>
        `(attributes.program_types: ANY(\"InWarehouse\") AND inventory(${warehouseId}, attributes.availability): ANY(\"IN_STOCK\", \"LOW_STOCK\", \"BACK_ORDER\", \"PRESELL\", \"SHIP_RESTRICTED\"))`,
    [GRS_WAREHOUSE_PICKUP_FACET_KEY]: warehouseId =>
        `attributes.program_types: ANY("UseWarehouseInventory") AND inventory(${warehouseId}, attributes.availability): ANY("IN_STOCK", "LOW_STOCK", "BACK_ORDER", "PRESELL", "SHIP_RESTRICTED")`,
};

let cachedQueryParamKeyToFacetKeyMap: Record<string, string> | null = null;

export const createQueryParamKeyToFacetKeyMap = (
    grsFacetConfig: GRSFacetConfig
): Record<string, string> => {
    if (cachedQueryParamKeyToFacetKeyMap) {
        return cachedQueryParamKeyToFacetKeyMap;
    }

    const queryParamKeyToFacetKeyMap: Record<string, string> = {};
    for (const [key, value] of Object.entries(grsFacetConfig)) {
        queryParamKeyToFacetKeyMap[value.queryParamKey ?? key] = key;
    }

    cachedQueryParamKeyToFacetKeyMap = queryParamKeyToFacetKeyMap;
    return queryParamKeyToFacetKeyMap;
};

export const getFacetKeyFromQueryParamKey = (
    queryParamKey: string,
    grsFacets: GRSFacetConfig
) => {
    const queryParamKeyToFacetKeyMap =
        createQueryParamKeyToFacetKeyMap(grsFacets);
    const facetKey = queryParamKeyToFacetKeyMap[queryParamKey];
    return facetKey;
};

export const getGRSFacetsFromConfig = (
    grsFacetConfig: ContentStackEntryDataProps<GRSFacetConfigProperties>
): GRSFacetConfig => {
    const grsAttributesFacets = grsFacetConfig?.properties?.[0]?.attributes;
    const updatedAttributesFacets: Record<string, any> = {};

    if (grsAttributesFacets) {
        // Update the keys to include the 'attributes.' prefix so they align with the GRS API
        // Contentstack would not allow a period in the key name so we have to add it here
        for (const [key, value] of Object.entries(grsAttributesFacets)) {
            const updatedKey = `attributes.${key}`;
            updatedAttributesFacets[updatedKey] = value;
        }
    }

    return {
        ...grsFacetConfig?.properties?.[0]?.standard,
        ...updatedAttributesFacets,
    };
};

const getOrderBy = (searchParams: ReadonlyURLSearchParams) => {
    const sortBy = searchParams.get('sortBy');

    switch (sortBy) {
        case 'score+desc': {
            return null;
        }
        case 'item_location_pricing_salePrice+desc': {
            return 'price desc';
        }
        case 'item_location_pricing_salePrice+asc': {
            return 'price';
        }
        case 'item_ratings+desc': {
            return 'rating desc';
        }
        case 'item_startDate+desc': {
            return 'attributes.start_date_epoch desc';
        }
        case 'item_page_views+desc': {
            return null;
        }
        default: {
            return null;
        }
    }
};

export const getGRSFilterBy = ({
    searchParams,
    whloc,
    grsFacetConfig,
    categoryTitle,
}: {
    searchParams: ReadonlyURLSearchParams | undefined;
    whloc: string | undefined;
    grsFacetConfig:
        | ContentStackEntryDataProps<GRSFacetConfigProperties>
        | undefined;
    categoryTitle: string | undefined;
}) => {
    if (!searchParams || !grsFacetConfig) return [];

    const grsFilterParams = new URLSearchParams();
    const grsFacets = getGRSFacetsFromConfig(grsFacetConfig);
    const grsFilterParamKeys = Object.keys(grsFacets);

    searchParams.forEach((value, queryParamKey) => {
        const facetKey = getFacetKeyFromQueryParamKey(queryParamKey, grsFacets);
        if (facetKey && grsFilterParamKeys.includes(facetKey)) {
            grsFilterParams.append(facetKey, value);
        }
    });

    if (categoryTitle) {
        grsFilterParams.append(GRS_CATEGORY_NAMES_FACET_KEY, categoryTitle);
    }

    const getGRSQueryRangeValue = (key: string, values: string[]) => {
        // Convert the string tuples into number pairs
        const parsedRanges = values
            .map(value => {
                const trimmedValue = value.trim();
                const match = trimmedValue.match(/\((\d+),(\d+)\)/);
                if (match && match[1] !== undefined && match[2] !== undefined) {
                    return [parseInt(match[1], 10), parseInt(match[2], 10)];
                }
                return null;
            })

            .filter((item): item is [number, number] => item !== null) as [
            number,
            number,
        ][];

        // Sort ranges by the start value
        parsedRanges.sort((a, b) => a[0] - b[0]);

        const consolidated: [number, number][] = [];

        for (const [start, end] of parsedRanges) {
            const lastRange = consolidated.at(-1);
            if (lastRange && lastRange[1] >= start) {
                // Merge overlapping or adjacent ranges
                lastRange[1] = Math.max(lastRange[1], end);
            } else {
                consolidated.push([start, end]);
            }
        }

        // Format the output string
        return consolidated
            .map(([s, e]) => `${key}: IN(${s}, ${e})`)
            .join(' OR ');
    };

    const getGRSQueryDynamicValue = (key: string, values: string[]) => {
        const formattedValues = values.map(value => `\"${value}\"`).join(', ');
        return `${key}: ANY(${formattedValues})`;
    };

    const getGRSQueryValue = (key: string, values: string[]) => {
        if (grsRangeValueFacets.includes(key)) {
            return getGRSQueryRangeValue(key, values);
        } else {
            return getGRSQueryDynamicValue(key, values);
        }
    };

    const grsFilterParamsEntries = Array.from(grsFilterParams.entries());

    const filterBy = grsFilterParamsEntries.map(([key, value]) => {
        // Categories can have commas in the value, other facets use comma to seperate multiple values
        const values =
            key === GRS_CATEGORY_NAMES_FACET_KEY
                ? [value]
                : splitByCommaOutsideParentheses(value);
        const filterValues = [];

        if (grsBooleanValueFacets.includes(key)) {
            if (values[0] === 'true' && whloc && grsKeyQueryMap[key]) {
                filterValues.push(grsKeyQueryMap[key](whloc));
            }
        } else {
            if (values.length > 0) {
                filterValues.push(getGRSQueryValue(key, values));
            }
        }

        return `${filterValues.join(' OR ')}`;
    });

    return filterBy;
};

const generateRequestBody = (
    grsConfig: GrsConfig,
    grsFacetConfig: ContentStackEntryDataProps<GRSFacetConfigProperties>,
    searchParams: ReadonlyURLSearchParams,
    currentPage: number,
    adobeSessionId: string,
    { loc, whloc, userLocation, userZipCode }: WarehouseSearchParams,
    categoryTitle: string | undefined,
    resultsPerPage: number
) => {
    let body = grsConfig?.required_request_parameters;
    const keyword = searchParams.get('keyword');

    const filterBy = getGRSFilterBy({
        searchParams,
        whloc,
        grsFacetConfig,
        categoryTitle,
    });
    const priceFacetWarehouse = getKeyValue(
        GRS_PRICE_FACET_WAREHOUSE_KEY,
        grsFacetConfig
    );

    const orderBy = getOrderBy(searchParams);

    const locations = loc?.split(',') || [];
    // "*" used in LW but causes error in GRS query
    const filteredLocations = locations.filter(loc => loc !== '*');

    const deliveryLocations = priceFacetWarehouse
        ? [priceFacetWarehouse, ...filteredLocations]
        : filteredLocations;

    const offset = resultsPerPage * (currentPage - 1);

    body = {
        ...body,
        visitorId: adobeSessionId,
        query: keyword,
        searchMode: 'page',
        pageSize: resultsPerPage,
        offset: offset,
        personalizationEnabled: false,
        warehouseId: whloc,
        shipToPostal: userZipCode,
        shipToState: userLocation,
        deliveryLocations,
        filterBy: filterBy ?? [],
        orderBy: orderBy,
    };
    return JSON.stringify(body);
};

/**
 * GRS Search Service.
 */

export async function GrsQuery(
    grsConfig: GrsConfig | undefined,
    grsFacetConfig:
        | ContentStackEntryDataProps<GRSFacetConfigProperties>
        | undefined,
    searchParams: ReadonlyURLSearchParams,
    lang: LocaleProps,
    properties: SearchPageConfigProperties,
    { loc, whloc, userLocation, userZipCode }: WarehouseSearchParams,
    pageType: string,
    site: string,
    adobeSessionId: string,
    categoryTitle: string | undefined
): Promise<any> {
    //return value should be updated later depending on how we want the response
    let returnValue: SearchResultGRS = {} as SearchResultGRS;
    const { revalidateInMs, resultsPerPage } = properties;

    if (!grsConfig || !grsFacetConfig) {
        return returnValue;
    }

    if (!searchParams.get('keyword') && pageType === PageTypes.SEARCH) {
        return returnValue;
    }

    const currentPage = Number(searchParams.get('currentPage')) || 1;

    const formattedLocale = lang
        .split('-')
        .map((part: string, index: number) => {
            if (index === 1) {
                return part.toUpperCase(); // Only capitalize the second part
            }
            return part;
        })
        .join('-');
    const requestHeaders = {
        ...grsConfig.required_request_headers,
        locale: formattedLocale,
        client_id: site,
    };
    const body = generateRequestBody(
        grsConfig,
        grsFacetConfig,
        searchParams,
        currentPage,
        adobeSessionId,
        {
            loc,
            whloc,
            userLocation,
            userZipCode,
        },
        categoryTitle,
        resultsPerPage
    );

    const response = await fetch(grsConfig?.endpoint, {
        method: 'POST',
        headers: {
            ...requestHeaders,
        },
        body: body,
    });

    if (!response.ok) {
        console.error('GRS ERROR', response.statusText);
        return returnValue;
    }
    let data: SearchResultGRS = await response.json();

    const pagination = {
        currentPage: currentPage,
        totalDocs: data?.searchResult?.totalSize,
        totalPages: getNumberOfPages(
            data?.searchResult?.totalSize || 0,
            resultsPerPage
        ),
    };

    const resultsMappedWithInventory = data?.searchResult?.results?.map(
        (result: any) => {
            const matchingInventory = data.inventoryResponse?.find(
                (inventory: InventoryResponse) =>
                    inventory.productId === result.id
            );
            return {
                ...result,
                inventory: matchingInventory,
            };
        }
    );

    returnValue = {
        ...data,
        pagination,
        searchResult: {
            ...data?.searchResult,
            results: resultsMappedWithInventory,
        },
    };

    const searchFacets = data?.searchResult?.facets;
    const doesFsaFacetExist =
        (
            searchFacets?.find(facet => facet.key === 'attributes.fsa_eligible')
                ?.values || []
        ).length > 0;

    const doesChdiFacetExist = !!searchFacets?.find(
        facet => facet.key === 'attributes.chdi_eligible'
    )?.values?.length;

    returnValue.isAdTargetingExplicitlyDisabled =
        doesFsaFacetExist || doesChdiFacetExist;
    // GRS currently does not support ad targeting exceptions so this is always false
    returnValue.isAdTargetingExceptionEnabled = false;

    returnValue.isAdTargetingEnabled =
        returnValue.isAdTargetingExceptionEnabled ||
        !returnValue.isAdTargetingExplicitlyDisabled;

    return returnValue;
}

export const isSearchResultGRS = (result: any): result is SearchResultGRS => {
    return result && Array.isArray(result?.searchResult?.results);
};
