import { SEARCH_SERVICE_PROVIDERS } from '@/constants/grs';
import {
    GRS_SEARCH_PROVIDER_SESSION_STORAGE_VALUE,
    SEARCH_PROVIDER_SESSION_STORAGE_KEY,
} from '@/src/constants/grs';
import { createUUID } from '@costcolabs/forge-digital-components';

export const getIsGRSSearchProviderSessionStorageSet = () => {
    const isGRSSearchProviderSessionStorageSet =
        sessionStorage
            .getItem(SEARCH_PROVIDER_SESSION_STORAGE_KEY)
            ?.toLowerCase() === GRS_SEARCH_PROVIDER_SESSION_STORAGE_VALUE;

    return isGRSSearchProviderSessionStorageSet;
};

export const setGRSSearchProviderSessionStorage = () => {
    return sessionStorage.setItem(
        SEARCH_PROVIDER_SESSION_STORAGE_KEY,
        GRS_SEARCH_PROVIDER_SESSION_STORAGE_VALUE
    );
};

export const getAdobeSessionId = ({
    searchService,
    adobeSessionIdLocalForage,
    hasLocalForageError,
    isLocalForageInitialzed,
}: {
    searchService:
        | (typeof SEARCH_SERVICE_PROVIDERS)[keyof typeof SEARCH_SERVICE_PROVIDERS]
        | null;
    adobeSessionIdLocalForage: string | null;
    hasLocalForageError: boolean;
    isLocalForageInitialzed: boolean;
}) => {
    if (searchService !== SEARCH_SERVICE_PROVIDERS.GRS) {
        // If search service is not GRS, return null to prevent unnecessary search api calls
        return null;
    } else if (!hasLocalForageError && !isLocalForageInitialzed) {
        // Return null to prevent returning new UUID before localforage reads value in indexedDB
        return null;
    } else if (adobeSessionIdLocalForage) {
        return adobeSessionIdLocalForage;
    } else {
        return createUUID();
    }
};
